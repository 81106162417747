@import url('https://fonts.googleapis.com/css2?family=Skranji&display=swap');


.container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    font-family: 'Skranji', cursive;
}

.header_container h1{
    color: aliceblue;
    margin-bottom: 10%;
}

.tech_card_container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1100px;
    margin: 1rem 0;
}

.tech_card{
    width: 15rem;
    height: 10rem;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
}

.tech_card:hover{
    background-color: var(--font-color-hover);
    transform: scale(1.05);
}


.tech_card span{
    font-size: 2rem;
    margin: 5px;
}

.tech_icons{
    height: 70px;
    border-radius: 50%;
}


@media screen and (max-width: 1150px) {
    
    .container{
        height: 100%;
    }

}

@media screen and (max-width: 600px) {
    
    .tech_card{
        width: 8rem;
        height: 4rem;
    }

    .tech_card span{
        font-size: 1rem;
    }

}