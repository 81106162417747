@import url('https://fonts.googleapis.com/css2?family=Skranji&display=swap');

.container{
    margin: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgb(37, 37, 37);
}

.buttonContainer{
    border: 2px solid black;
    padding: 10px;
    border-radius: 7px;
    box-shadow: inset 0 20px 4px -19px rgba(255, 255, 255, .7);

}

.buttonContainer button{
    cursor: pointer;
    outline: none;
    width: 120px;
    height: 70px;
    font-family: 'Skranji', cursive;
    font-size: 25px;
    color: white;
    text-shadow: 0 2px 5px black;
    background: linear-gradient(to top, #696969, #575757);
    border-color: 2px solid black;
    border-radius: 7px;
    box-shadow: inset 0 20px 4px -19px rgba(255, 255, 255, .4), 0 12px 12px 0 rgba(0, 0, 0, .3);
}

.buttonContainer button:hover{
    background: linear-gradient(to bottom, #696969, #575757);
}

.buttonContainer button:active{
    transform: translateY(3px);
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .3);
}