@import url('https://fonts.googleapis.com/css2?family=Skranji&display=swap');

.container{
    height: 100vh;
    margin: 3rem 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    text-align: center;
    font-family: 'Skranji', cursive;
}

.container img{
    border-radius: 60%;
    border: 0.5px solid rgb(218, 218, 218);
    margin-bottom: 1rem;
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
}

.textContainer{
    max-width: 600px;
}

.textContainer h1{
    font-size: 5rem;
    margin-bottom: 10%;
}

.textContainer p{
    font-size: 1.5rem;
    background-color: #212529;
}

@media screen and (max-width: 600px) {
    .container{
        flex-direction: column;
    }

    .imageContainer{
        width: 10rem;
    }

    .textContainer{
        max-width: 380px;
    }

    .textContainer h1{
        font-size: 2rem;
    }
    
    .textContainer p{
        font-size: 1rem;
    }
}