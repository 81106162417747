@import url('https://fonts.googleapis.com/css2?family=Skranji&display=swap');

.container{
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: 'Skranji', cursive;
}

.container h1{
    font-size: 6rem;
}

.container span{
    font-size: 2rem;
}



@media screen and (max-width: 600px) {
    .container h1{
        font-size: 4rem;
    }
    .container span{
        font-size: 1rem;
    }
}