@import url('https://fonts.googleapis.com/css2?family=Skranji&display=swap');


.container{
    height: 100%;
    padding-top: 20px;
    font-family: 'Skranji', cursive;
}

.header_container h1{
    color: aliceblue;
    text-align: center;
    font-size: 3rem;
}

.header_container p{
    text-align: center;
    color: aliceblue;
    font-size: 1.5rem;
}

.github_icon{
    height: 2rem;
    background-color: aliceblue;
    margin-left: 1rem;
}


.bootstrap_container{
    padding: 2rem 0;
}

.bootstrap_row{
    justify-content: space-evenly;
}

.card{
    width: 30rem !important;
    height: 15rem !important;
    margin-bottom: 2rem !important  ;
}

.card:hover{
    cursor: pointer;
}

.card img{
    width: 100%;
    height: 100%;
}




@media screen and (max-width: 600px) {
    .container{
        padding-left: 2%;
        padding-right: 2%;
    }
}