@import url('https://fonts.googleapis.com/css2?family=Skranji&display=swap');

html {
    box-sizing: border-box;
}


.container{
    margin: 0;
    font-family: 'Skranji', cursive;
    background-color: whitesmoke;
}

h1{
    margin: 0;
    text-align: center;
    margin-bottom: 25px;
    font-size: 40px;
    letter-spacing: 5px;
}

/* Loader */
.loader{
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .8);
}

/* Best Way To Center Object */
.loader img{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Image Container */

.imageContainer{
    margin: 10px 30%;
}

.imageContainer img{
    width: 100%;
    margin-top: 5px;
}


/* Media Query: Larg Smartphone */
@media screen and (max-width: 600px){
    h1{
        font-size: 14px;
    }
    
    .imageContainer{
        margin: 10px;
    }
}